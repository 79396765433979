import * as yup from 'yup';

// export const url = 'http://localhost:5000';
export const url = 'https://lockyourlands.uw.r.appspot.com';

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}$/;

export const loginSchema = yup.object().shape({
  Email: yup.string().email('Please enter a valid email').required('Email is Required'),

  Password: yup
    .string()
    .min(7)
    .matches(passwordRules, {
      message: 'Please create a stronger password(1 upper case letter, 1 lower case letter, 1 numeric digit)',
    })
    .required('Password is required'),
});

export const forgotPasswordSchema = yup.object().shape({
  Email: yup.string().email('Please enter a valid email').required('Email is Required'),
});

export const registerSchema = yup.object().shape({
  Email: yup.string().email('Please enter a valid email').required('Email is Required'),
  Address: yup.string().min(2).max(50).required('Please enter your Address'),
  Firstname: yup
    .string()
    .min(2)
    .max(30)
    .matches(/^[A-Za-z\s]+$/, 'Firstname must only contain letters')
    .required('Please enter your Firstname'),
  Lastname: yup
    .string()
    .min(2)
    .max(30)
    .matches(/^[A-Za-z\s]+$/, 'Lastname must only contain letters')
    .required('Please enter your Lastname'),
  NIN: yup.number().min(9).required('Please enter your NIN'),
  Category: yup.string().min(4).required('Please enter your Category'),

  License: yup.string().when('Category', {
    is: (value) => value === 'surveyor',
    then: (schema) => schema.required('Please enter your License Number'),
    otherwise: (schema) => schema,
  }),

  AgentLicense: yup.string().when('Category', {
    is: (value) => value === 'agent',
    then: (schema) => schema.required('Please enter your License Number'),
    otherwise: (schema) => schema,
  }),

  Phone: yup.number().min(9).required('Please enter your Phone Number'),

  LicenseYear: yup.date().when('Category', {
    is: (value) => value === 'surveyor',
    then: (schema) => schema.required('Please enter your License Year'),
    otherwise: (schema) => schema,
  }),
  Password: yup
    .string()
    .min(8)
    .matches(passwordRules, {
      message: 'Please create a stronger password(1 upper case letter, 1 lower case letter, 1 numeric digit)',
    })
    .required('Password is required'),
  ConfirmPassword: yup
    .string()
    .oneOf([yup.ref('Password'), null], 'Passwords must match')
    .required('Please Confirm your Password'),
});

export const ConfirmSchema = yup.object().shape({
  Comment: yup.string().min(2).max(100).required('Please enter your Comment'),
  LandRegistry: yup.string().required('Please select add land Registry proof'),
  ProbateRegistry: yup.string().required('Please select add probate Registry proof'),
  CacSearch: yup.string().required('Please select add Cac search proof'),
  AgentUnbalancedPayment: yup.string().required('Please add Unbalanced Payment '),
});

export const ConfirmSurveySchema = yup.object().shape({
  Comment: yup.string().min(2).max(100).required('Please enter your Comment'),
  surveyPdf: yup.string().required('Please select image proofs'),
  UnbalancedPayment: yup.string().required('Please  add Unbalanced Payment '),
});

export const updateDetailsSchema = yup.object({
  firstName: yup.string().required('First name is required').min(2, 'First name should be at least 2 characters'),
  lastName: yup.string().required('Last name is required').min(2, 'Last name should be at least 2 characters'),
  address: yup.string().required('Address is required').min(5, 'Address should be at least 5 characters'),
  phone: yup
    .string()
    .required('Phone number is required')
    .matches(/^\d{11}$/, 'Phone number must be 11 digits'),
});

export const changePasswordSchema = yup.object({
  oldPassword: yup.string().required('Current password is required'),
  newPassword: yup
    .string()
    .required('New password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    ),
  confirmNewPassword: yup
    .string()
    .required('Please confirm your password')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});
