import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import usersReducer from '../features/users/usersSlice';
import userReducer from '../features/user/userSlice';
import propertydocumentsReducer from '../features/propertydocuments/propertydocumentSlice';
import landdocumentsReducer from '../features/landdocuments/landdocumentSlice';
import propertydocumentReducer from '../features/propertydocument/propertydocumentSlice';
import landdocumentReducer from '../features/landdocument/landdocumentSlice';

export const store = configureStore({
  reducer: {
    agentAuth: authReducer,
    users: usersReducer,
    user: userReducer,
    propertydocuments: propertydocumentsReducer,
    propertydocument: propertydocumentReducer,
    landdocuments: landdocumentsReducer,
    landdocument: landdocumentReducer,
  },
});
