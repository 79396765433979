import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService, { logoutError } from './authService';
import { createAsyncThunkWithHandler } from '../api';

const user = JSON.parse(localStorage.getItem('Agentuser'));

const initialState = {
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

if (user) {
  initialState.user = user;
}

export const login = createAsyncThunkWithHandler('AgentAuth/login', async (user, _) => {
  const data = await authService.login(user);
  return data;
});

export const register = createAsyncThunkWithHandler('AgentAuth/registerSurveyors', async (user, _) => {
  const data = await authService.register(user);
  return data;
});

export const verifySurveyor = createAsyncThunkWithHandler('AgentAuth/verifySurveyor', async (data, _) => {
  const response = await authService.verifySurveyor(data);
  return response;
});

export const forgotPasswordSurveyor = createAsyncThunkWithHandler(
  'AgentAuth/forgotPasswordSurveyor',
  async (email, _) => {
    const data = await authService.forgotPasswordSurveyor(email);
    return data;
  }
);

export const changePasswordSurveyors = createAsyncThunkWithHandler(
  'AgentAuth/changePasswordSurveyors',
  async (userData, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const data = await authService.changePasswordSurveyors(userData, token);
    return data;
  }
);

export const updateSurveyorProfile = createAsyncThunkWithHandler(
  'AgentAuth/updateSurveyorProfile',
  async (userData, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const data = await authService.updateSurveyorProfile(userData, token);
    return data;
  }
);

export const logout = createAsyncThunk('AgentAuth/logout', async () => {
  await authService.logout();
});

// Agents Actions

export const registerAgents = createAsyncThunkWithHandler('AgentAuth/registerAgents', async (user, _) => {
  const data = await authService.registerAgent(user);
  return data;
});

export const verifyAgent = createAsyncThunkWithHandler('AgentAuth/verifyAgent', async (data, _) => {
  const response = await authService.verifyAgent(data);
  return response;
});

export const loginAgent = createAsyncThunkWithHandler('AgentAuth/Agentlogin', async (user, _) => {
  const data = await authService.loginAgent(user);
  return data;
});

export const forgotPasswordAgent = createAsyncThunkWithHandler('AgentAuth/forgotPasswordAgent', async (email, _) => {
  const data = await authService.forgotPasswordAgent(email);
  return data;
});

export const changePasswordAgent = createAsyncThunkWithHandler(
  'AgentAuth/changePasswordAgent',
  async (userData, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const data = await authService.changePasswordAgent(userData, token);
    return data;
  }
);

export const updateAgentProfile = createAsyncThunkWithHandler(
  'AgentAuth/updateAgentProfile',
  async (userData, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const data = await authService.updateAgentProfile(userData, token);
    return data;
  }
);

const AgentAuthSlice = createSlice({
  name: 'AgentAuth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.user = action.payload;
        state.isSuccess = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        state.isSuccess = false;
      })
      .addCase(loginAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.user = action.payload;
        state.isSuccess = true;
      })
      .addCase(loginAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        state.isSuccess = false;
      })
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.user = null;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        state.isSuccess = false;
      })
      .addCase(verifySurveyor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifySurveyor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.message = action.payload;
        state.isSuccess = true;
      })
      .addCase(verifySurveyor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(verifyAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.message = action.payload;
        state.isSuccess = true;
      })
      .addCase(verifyAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })

      .addCase(registerAgents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerAgents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.user = action.payload;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(registerAgents.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        state.isSuccess = false;
      })
      .addCase(forgotPasswordSurveyor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPasswordSurveyor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.message = action.payload;
        state.isSuccess = true;
      })
      .addCase(forgotPasswordSurveyor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(forgotPasswordAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPasswordAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.message = action.payload;
        state.isSuccess = true;
      })
      .addCase(forgotPasswordAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(changePasswordSurveyors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changePasswordSurveyors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.message = action.payload;
        state.isSuccess = true;
      })
      .addCase(changePasswordSurveyors.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(changePasswordAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changePasswordAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.message = action.payload;
        state.isSuccess = true;
      })
      .addCase(changePasswordAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(updateSurveyorProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSurveyorProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.user = action.payload;
        state.message = 'Profile Updated Successfully';
        state.isSuccess = true;
      })
      .addCase(updateSurveyorProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(updateAgentProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAgentProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.user = action.payload;
        state.message = 'Profile Updated Successfully';
        state.isSuccess = true;
      })
      .addCase(updateAgentProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })

      .addCase(logout.fulfilled, (state) => {
        state.user = null;
      });
  },
});

export const { reset } = AgentAuthSlice.actions;
export default AgentAuthSlice.reducer;
