import axios from 'axios';
import { API_URL } from '../api';

const getDocument = async (token, id, documentId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/surveyors/surveyor/land/${documentId}/${id}`, config);

  return response.data;
};

const getDocumentByAgent = async (token, id, documentId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/agents/agent/land/${documentId}/${id}`, config);

  return response.data;
};

const verifyLandDocumentStatus = async (token, id, documentId, formdata) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/surveyors/surveyor/verify_property_land/${documentId}/${id}`,
    formdata,
    config
  );

  return response.data;
};

const verifyLandDocumentStatusByAgent = async (token, id, documentId, formdata) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/agents/agent/verify_property_land/${documentId}/${id}`,
    formdata,
    config
  );

  return response.data;
};

const verifySurveyStatus = async (token, id, documentId, formdata) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/surveyors/surveyor/land/verify_survey/${documentId}/${id}`,
    formdata,
    config
  );

  return response.data;
};

const flagDocumentStatus = async (token, id, documentId, formdata) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/surveyors/surveyor/unverify_property_land/${documentId}/${id}`,
    formdata,
    config
  );

  return response.data;
};

const flagDocumentStatusByAgent = async (token, id, documentId, formdata) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/agents/agent/unverify_property_land/${documentId}/${id}`,
    formdata,
    config
  );

  return response.data;
};

const documentService = {
  getDocument,
  verifyLandDocumentStatus,
  flagDocumentStatus,
  verifySurveyStatus,
  flagDocumentStatusByAgent,
  verifyLandDocumentStatusByAgent,
  getDocumentByAgent,
};
export default documentService;
