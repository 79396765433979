import { Box, Chip, Container, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import notFound from '../assets/notFound.svg';
import Loader from '../components/loader';
import {
  fetchUnverifiedLandDocsByAgent,
  fetchUnverifiedLandDocsBySurveyor,
} from '../features/landdocuments/landdocumentSlice';
import {
  fetchUnverifiedPropertyDocsByAgent,
  fetchUnverifiedPropertyDocsBySurveyor,
  reset,
} from '../features/propertydocuments/propertydocumentSlice';

const UnverifiedDocuments = () => {
  const dispatch = useDispatch();
  const verifiedImg = '/assets/icons/verified.png';
  const unverifiedImg = '/assets/icons/unverified.png';
  const pendingImg = '/assets/icons/pending.png';
  const viewImg = '/assets/icons/search.png';
  const { docs: propertyDocs, isLoading, isError, message } = useSelector((state) => state.propertydocuments);
  const { docs: landDocs } = useSelector((state) => state.landdocuments);
  const { user } = useSelector((state) => state.agentAuth);

  useEffect(() => {
    if (user.AgentType === 'Agent') {
      dispatch(fetchUnverifiedPropertyDocsByAgent());
      dispatch(fetchUnverifiedLandDocsByAgent());
    } else {
      dispatch(fetchUnverifiedPropertyDocsBySurveyor());
      dispatch(fetchUnverifiedLandDocsBySurveyor());
    }
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          reset();
        },
      });
    }
  }, [isError, message]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 40 },
    {
      field: 'Firstname',
      headerName: 'Firstname',
      width: 100,
    },
    {
      field: 'Lastname',
      headerName: 'Lastname',
      width: 100,
    },

    {
      field: 'Address',
      headerName: 'Address',
      width: 280,
    },
    {
      field: 'Type',
      headerName: 'Type',
      width: 130,
    },

    {
      field: 'Status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Chip
              variant="outlined"
              color={`${params.row.Status === 'Not Verified' ? 'error' : 'warning'}`}
              label={`${params.row.Status === 'Not Verified' ? 'Not Verified' : 'Pending'}`}
              icon={
                <img
                  src={params.row.Status === 'Not Verified' ? unverifiedImg : pendingImg}
                  alt="verified_img"
                  className="h-4 w-4"
                />
              }
              size="small"
              sx={{ cursor: 'pointer' }}
            />
          </>
        );
      },
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={
                params.row.Type === 'Built Property'
                  ? `/dashboard/documents/property_document/${params.row.docId}`
                  : `/dashboard/documents/land_document/${params.row.docId}`
              }
            >
              <Chip
                variant="contained"
                color="info"
                label="View"
                icon={<img src={viewImg} alt="verified_img" className="h-4 w-4" />}
                size="small"
                sx={{ cursor: 'pointer' }}
              />
            </Link>
          </>
        );
      },
    },
  ];

  const rows = [];

  const combinedDocs = [];

  if (propertyDocs && propertyDocs.length > 0) {
    combinedDocs.push(...propertyDocs);
  }

  if (landDocs && landDocs.length > 0) {
    combinedDocs.push(...landDocs);
  }

  combinedDocs?.forEach((doc, index) => {
    const propertyType = doc.property ? 'property' : 'land';
    rows.push({
      docId: doc.document._id,
      id: index + 1,
      Firstname: doc.user.Firstname,
      Lastname: doc.user.Lastname,
      Email: doc.user.Email,
      Status: doc.document.Status,
      Address: `${doc[propertyType].Address}, ${doc[propertyType].Country},  ${doc[propertyType].State} `,
      Type: propertyType === 'property' ? 'Built Property' : 'Landed Property',
    });
  });

  return (
    <>
      <Helmet>
        <title> Documents | Lock.your.Land Dashboard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Unverified documents
          </Typography>
        </Stack>
        {isLoading ? (
          <Box sx={{ width: '100%' }} className="flex items-center justify-center">
            <Loader />
          </Box>
        ) : (
          <Box sx={{ width: '100%' }} className="flex items-center justify-center">
            {propertyDocs?.length < 1 && landDocs?.length < 1 ? (
              <img src={notFound} alt="not found" className="w-72" />
            ) : (
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                checkboxSelection
                disableRowSelectionOnClick
              />
            )}
          </Box>
        )}
      </Container>
    </>
  );
};

export default UnverifiedDocuments;
